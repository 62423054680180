import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const OfficesGrid = () => {
  const data = useStaticQuery(graphql`
      query {
        allOffice (sort: { fields: fullName, order: ASC })  {
            nodes {
              fullName
              address {
                city
                state
                street1
                street2
                postal
                country
                text
              }
            }
          }
      }
    `)
  const offices = data.allOffice?.nodes?.map((x: any) => x)

  return (
    <div>
      <h1 className="offices-title text-center text-40 md:text-65 mt-49" >Our Offices</h1>
      <div className="flex flex-row flex-wrap justify-center items-center containers px-1 py-7">
        {offices.map((office: any, index: any) => (
          <div className="flex flex-col items-center w-full lg:w-1/3 leading-[0.01em]" key={index}>
            <h2 className="text-22 font-bold">{office.fullName}</h2>
            {office.address.street2 ? (
              <p className="text-14 font-hairline">{office.address.street1}, {office.address.street2}</p>
            ) : (
              <p>{office.address.street1}</p>
            )}
            <p className="text-14 font-hairline">{office.address.city}, {office.address.state}, {office.address.country ? `, ${office.address.country}` : ''} {office.address.postal}</p>
            <p>{office.address.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OfficesGrid